import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { websiteName } from '../../utils/environment';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

const TermsOfServicePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>{"Terms of Service | " + websiteName}</title>
            </Helmet>
            <div className="flex items-center justify-center min-h-screen">
                <div className="max-w-4xl mx-auto p-6">
                    <div className="flex items-center mb-4">
                        <Link to="/">
                            <ArrowLeftIcon className="w-6 h-6 text-gray-500 cursor-pointer mr-2" />
                        </Link>
                        <h1 className="text-4xl font-bold mb-4">Terms of Service for Lyora.io</h1>
                    </div>

                    <h2 className="text-2xl mb-4">Last Updated: May 14, 2024</h2>
                    <h3 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h3>
                    <p className="mb-4">
                        Welcome to Lyora.io. By accessing our website, you agree to be bound by these Terms of Service and to use our site in accordance with these terms, our Privacy Policy, and any additional terms and conditions that may apply. If you do not agree to these terms, please do not use our website.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">2. Modification of Terms</h3>
                    <p className="mb-4">
                        We reserve the right, at our discretion, to change these Terms at any time. Your continued use of Lyora.io after any changes indicates your acceptance of the new Terms.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">3. Use of Website</h3>
                    <p className="mb-4">
                        Lyora.io is provided solely for your personal, non-commercial use. You may not use this site for any illegal or unauthorized purpose.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">4. Intellectual Property</h3>
                    <p className="mb-4">
                        All content included on this site, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the site, is the property of Lyora.io or its suppliers and protected by copyright and intellectual property laws.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">5. Disclaimer of Warranties</h3>
                    <p className="mb-4">
                        Our website is provided "as is." Lyora.io makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">6. Limitation of Liability</h3>
                    <p className="mb-4">
                        Lyora.io or its suppliers will not be liable for any damages arising out of the use or inability to use the materials on Lyora.io's website, even if Lyora.io or an authorized representative has been notified orally or in writing of the possibility of such damage.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">7. User Accounts</h3>
                    <p className="mb-4">
                        If you create an account on Lyora.io, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">8. Governing Law</h3>
                    <p className="mb-4">
                        Any claim relating to Lyora.io's website shall be governed by the laws of Florida, USA, without regard to its conflict of law provisions.
                    </p>
                    {/* <h3 className="text-xl font-semibold mb-2">9. Contact Information</h3>
                    <p className="mb-4">
                        For any questions or concerns about these Terms, please contact us at:
                        <a href="mailto:info@lyora.io" className="text-blue-500 hover:underline"> info@lyora.io</a>
                    </p> */}
                    <h3 className="text-xl font-semibold mb-2">Thank you for using Lyora.io!</h3>
                    <div className="h-12"></div>
                </div>
            </div>


        </>
    );
};

export default TermsOfServicePolicy;