import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { websiteName } from '../../utils/environment';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>{"Privacy Policy | " + websiteName}</title>
            </Helmet>
            <div className="flex items-center justify-center min-h-screen">
                <div className="max-w-4xl mx-auto p-6">
                    <div className="flex items-center mb-4">
                        <Link to="/">
                        <ArrowLeftIcon className="w-6 h-6 text-gray-500 cursor-pointer mr-2" />
                        </Link>
                        <h1 className="text-4xl font-bold">Privacy Policy for Lyora.io</h1>
                    </div>
                    <h2 className="text-2xl mb-4">Last Updated: May 14, 2024</h2>
                    <p className="mb-4">
                        At Lyora.io, protecting your privacy is a priority. This Privacy Policy outlines how we collect, use, protect, and disclose the information you provide while using our website.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Information Collection:</h3>
                    <p className="mb-4">
                        We only collect your email address when you sign up through our website.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Use of Information:</h3>
                    <p className="mb-4">
                        Your email address is used solely to:
                    </p>
                    <p className="mb-4">- Communicate with you about your account and provide updates about our services.</p>
                    <p className="mb-4">- Send you occasional insights, updates, or promotional information related to Lyora.io.</p>
                    <h3 className="text-xl font-semibold mb-2">Protection of Information:</h3>
                    <p className="mb-4">
                        We implement strong security measures to ensure your personal information is safe. Your email address is stored securely and is not shared, sold, or transferred to any third parties.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Disclosure of Information:</h3>
                    <p className="mb-4">
                        Lyora.io values your trust and guarantees that your email address is used exclusively for the purposes mentioned above.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Children's Privacy:</h3>
                    <p className="mb-4">
                        Lyora.io does not knowingly collect personal information from children under the age of 13. If we discover that we have collected information from a child under 13 without parental consent, we will take immediate action to delete it. Parents or guardians who suspect their child has provided personal information on Lyora.io should contact us promptly.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">Changes to Our Privacy Policy:</h3>
                    <p className="mb-4">
                        We may revise this Privacy Policy periodically to reflect updates to our practices or to comply with legal requirements. Any changes will be posted on this page, and the “Last Updated” date will be revised accordingly.
                    </p>
                    {/* <h3 className="text-xl font-semibold mb-2">Contacting Us:</h3>
                    <p className="mb-4">
                        If you have any questions or concerns about this Privacy Policy, feel free to contact us at:
                        <a href="mailto:info@lyora.io" className="text-blue-500 hover:underline"> info@lyora.io</a>
                    </p> */}
                    <h3 className="text-xl font-semibold mb-2">Thank you for trusting Lyora.io!</h3>
                    <div className="h-12"></div>
                </div>
            </div>

        </>
    );
};

export default PrivacyPolicy;