//this is the thank you page that will be displayed after new user has signed up on the landing page
import React from 'react';

const ThankyouPage = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-base-200">
        <div className="text-center">
            <h1 className="text-6xl lg:text-9xl font-bold text-primary">Thank you!</h1>
            <p className="text-xl mt-4">
            Thank you for signing up. We will be in touch soon.
            </p>
            <div className="mt-6">
            <a href="/" className="btn btn-primary rounded-full px-6 mt-4">Go to Home</a>
            </div>
        </div>
        </div>
    );
};

export default ThankyouPage

