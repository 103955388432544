// NotFound404Page.js
import React from 'react';

const NotFound404Page = () => {
  return (
        <div className="flex items-center justify-center min-h-screen bg-base-200">
      <div className="text-center">
        <h1 className="text-6xl lg:text-9xl font-bold text-primary">404</h1>
        <p className="text-xl mt-4">
          Oops! The page you requested is either missing or restricted.<br />
          Please log in or head back to the home page.
        </p>
        <div className="mt-6">
          <a href="/" className="btn btn-primary rounded-full px-6 mt-4">Go to Home</a>
        </div>
      </div>
    </div>
  );
};


export default NotFound404Page







